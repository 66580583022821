import React, { useState, useEffect } from "react";
import env from "../env-config";
import { authMethods } from "../firebase/authMethods";
import { firestoreMethods } from "../firebase/firestoreMethods";

const AuthProvider = (props) => {
  const [inputs, setInputs] = useState(env.dev.inputs);
  const [errors, setErrors] = useState([]);
  const [user, setUser] = useState(undefined);
  const [token, setToken] = useState(window.localStorage.getItem("token"));
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    authMethods.initUserListener(token, setUser, setToken, setIsLoading);
  }, []);

  async function handleSendSignInLinkToEmail(language, checkIfRegistered) {
    setErrors([]);
    return await authMethods.sendSignInLinkToEmail(
      inputs.email,
      language,
      checkIfRegistered,
      setErrors
    );
  }

  function isSignInWithEmailLink(url) {
    return authMethods.isSignInWithEmailLink(url);
  }

  function handleSigninEmailLink(email, url) {
    authMethods.signInWithEmailLink(email, url, setErrors, setToken);
  }

  function handleSignupEmail() {
    // middle man between firebase and signup
    console.log("handleSignupEmail");
    // calling signup from firebase server

    if (inputs.marketingOptin) {
      firestoreMethods.createMarketingOptin(inputs.email);
    }

    authMethods.signupEmail(inputs.email, inputs.password, setErrors, setToken);
  }

  function handleSigninEmail() {
    //changed to handleSingin
    console.log("handleSignin!!!!");
    // made signup signin
    authMethods.signinEmail(inputs.email, inputs.password, setErrors, setToken);
    console.log(errors, token);
  }

  function handlePasswordResetEmail() {
    console.log("handlePasswordResetEmail!");
    authMethods.passwordResetEmail(inputs.email, setErrors);
  }

  function handleSignout() {
    const res = authMethods.signout(setErrors, setToken);
    if (res) {
      setUser(undefined);
    }

    return res;
  }

  return (
    <firebaseAuth.Provider
      value={{
        //replaced test with handleSignupEmail
        handleSendSignInLinkToEmail,
        isSignInWithEmailLink,
        handleSigninEmailLink,

        handleSignupEmail,
        handleSigninEmail,
        handleSignout,
        inputs,

        setInputs,
        handlePasswordResetEmail,

        // handleGoogleAuth,
        isLoading,
        token,
        user,
        setUser,
        errors,
      }}
    >
      {props.children}
    </firebaseAuth.Provider>
  );
};

export default AuthProvider;
export const firebaseAuth = React.createContext();
