import { useState, useEffect, useContext } from "react";
import { LangContext } from "../provider/LanguageProvider";
const contentful = require("contentful");

export default function useContentful(contentType) {
  const [item, setItem] = useState({});

  const { language } = useContext(LangContext);

  const client = contentful.createClient({
    space: process.env.REACT_APP_APP_CONTENTFUL_SPACE,
    accessToken: process.env.REACT_APP_APP_CONTENTFUL_ACCESS_TOKEN,
    environment: process.env.REACT_APP_APP_CONTENTFUL_ENVIRONMENT,
  });

  useEffect(() => {
    const fetchData = async () => {
      // console.log(
      //   "fetching contentful data for language: " + language + " and contentType: " + contentType
      // );
      if (!language) return;

      const entries = await client.getEntries({ content_type: contentType, locale: language });

      const filtered = entries.items.filter((e) => e.sys.contentType.sys.id === contentType);

      setItem(filtered[0]);
    };

    // console.log("contentful hook: " + contentType + " " + language);
    fetchData();
  }, [language, contentType]);

  return item?.fields;
}
