import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";

import { IconButton, Snackbar } from "@mui/material";

function SnackProvider(props) {
  const [open, setOpen] = useState(false);
  const [context, setContext] = useState({
    message: "Erfolg!",
    button: "Weiter",
  });

  function triggerSnackbar(updated) {
    setOpen(true);
    setContext({ ...context, ...updated });
  }

  function handleClose(event) {
    setOpen(false);
    //context.closeFn();
  }

  return (
    <snackNotification.Provider
      value={{
        triggerSnackbar,
      }}
    >
      <>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={open}
          autoHideDuration={2000}
          onClose={() => {
            return handleClose();
          }}
          message={context.message}
          action={
            <>
              {/* <Button color="primary" size="small" onClick={handleClose}>
                {context.button}
              </Button> */}
              <IconButton aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </>
          }
        />
        {props.children}
      </>
    </snackNotification.Provider>
  );
}

export default SnackProvider;
export const snackNotification = React.createContext();
