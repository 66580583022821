import React, { useContext, useEffect, useState } from "react";
import { StyledButton } from "../components/Customs";
import useContentful from "../hooks/useContentful";
import LoadingWrapper from "../components/LoadingWrapper";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Wrapper from "../components/Wrapper";
import { Box, Typography } from "@mui/material";

import { firebaseAuth } from "../provider/AuthProvider";

const StartPage = () => {
  const [testsAvailable, setTestsAvailable] = useState(false);

  const { isLoading, user } = useContext(firebaseAuth);
  const fields = useContentful("start");

  useEffect(() => {
    console.log("home page loaded...");
    console.log("user: ", user);
    if (user) {
      setTestsAvailable(user.appUser.availableTests > 0);
    } else {
      setTestsAvailable(false);
    }
  }, [user]);

  if (!fields || isLoading) {
    console.log("Home loading...");
    console.log("isLoading: ", isLoading);
    console.log("fields.loaded: ", !!fields);
    return <LoadingWrapper title="Loading Application..." />;
  }

  console.log("Tests Available: ", testsAvailable);

  return (
    <Wrapper>
      <Typography variant="h3" align="center">
        {fields.title}
      </Typography>
      {/* <YoutubeEmbed id={fields.youtubeId} /> */}
      {testsAvailable && (
        <Typography component="div">{documentToReactComponents(fields.description)}</Typography>
      )}
      {!testsAvailable && (
        <Typography component="div">
          {documentToReactComponents(fields.descriptionTestsUnavailable)}
        </Typography>
      )}
      <Box display="flex" justifyContent="space-between">
        {testsAvailable && (
          <StyledButton variant="contained" href="/select" color="primary">
            {fields.button}
          </StyledButton>
        )}
        {!testsAvailable && (
          <StyledButton variant="contained" href="/order" color="primary">
            {fields.buttonTestsUnavailable || "Get Additional Tests"}
          </StyledButton>
        )}
      </Box>
    </Wrapper>
  );
};

export default StartPage;
