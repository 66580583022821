import React, { useState, useEffect } from "react";

import { firestoreMethods } from "../firebase/firestoreMethods";

import dump from "../tmp/dump-selected.json";
import env from "../env-config.js";
import shuffleArray from "../lib/shuffleArray";
import { DEFAULT_LANGUAGE } from "./LanguageProvider.js";

const ValueProvider = (props) => {
  const [values, setValues] = useState([]);
  const [surveyResults, setSurveyResults] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [loadedValues, setLoadedValues] = useState({});

  function values04(vals) {
    let results = [];

    if (vals) {
      const keys = [...vals.keys()];

      results = keys.map((key) => ({
        name: vals.get(key).translations[DEFAULT_LANGUAGE].value,
        selected: null,
        adjusted: false,
        focused: 0,
        id: key,
        value: vals.get(key).clone(),
      }));
    }
    return results;
  }

  useEffect(() => {
    async function loadValues() {
      const newValues = await firestoreMethods.loadValues();
      console.log("Loaded " + newValues.size + "values from firestore.");
      setLoadedValues(newValues);
      setInitialized(true);
    }

    loadValues();

    if (env.dev.dumped) {
      setValues(dump);
    }
  }, []);

  function initValues() {
    setValues(shuffleArray(values04(loadedValues)));
    //setMeta(meta03());
  }

  function initSurveyResults() {
    setSurveyResults(null);
  }

  function handleUpdate(value, updatedAttributes) {
    // console.log("update value");
    // console.log(value);
    // console.log(updatedAttributes);

    let temp = [...values];
    const index = values.findIndex((e) => e.name === value.name);
    const next = { ...temp[index], ...updatedAttributes };

    temp[index] = next;
    setValues(temp);
  }

  function getValue(name) {
    return [...values].filter((e) => e.name === name).pop();
  }

  function bumpSelectedToAdjusted() {
    const temp = [...values].map((value) => {
      if (value.selected) {
        value.adjusted = true;
      }
      return value;
    });

    setValues(temp);
  }

  async function persistValues(userId) {
    console.log("persisting values for user: ", userId);
    const id = await firestoreMethods.createResultsFromValues(values, surveyResults, userId);
    localStorage.setItem(`has-created-values-${id}`, true);
    return id;
  }

  return (
    <valueData.Provider
      value={{
        values,
        initialized,
        initValues,
        setValues,
        handleUpdate,
        getValue,
        bumpSelectedToAdjusted,
        persistValues,
        surveyResults,
        setSurveyResults,
        initSurveyResults,
      }}
    >
      {props.children}
    </valueData.Provider>
  );
};

export default ValueProvider;
export const valueData = React.createContext();
