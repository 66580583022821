import { httpsCallable } from "firebase/functions";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { functions } from "../firebase/firebaseApp";
import { firebaseAuth } from "../provider/AuthProvider";

const OrderCompletion = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState("");

  const { user } = useContext(firebaseAuth);

  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;

  useEffect(() => {
    if (!(user && search)) return;

    const urlParams = new URLSearchParams(search);
    const sessionId = urlParams.get("session_id");

    const sessionFnc = httpsCallable(functions, "api/session-status");
    sessionFnc({ sessionId }).then((result) => {
      const clS = result.data;
      setStatus(clS.status);
      setCustomerEmail(clS.customer_email);
    });
  }, [search, user]);

  if (status === "open") {
    navigate("/checkout");
  }

  if (status === "complete") {
    return (
      <section id="success">
        <p>
          We appreciate your business! A confirmation email will be sent to {customerEmail}. If you
          have any questions, please email{" "}
          <a href="mailto:orders@example.com">orders@example.com</a>.
        </p>
      </section>
    );
  }

  return null;
};

export default OrderCompletion;
