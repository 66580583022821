import React, { useContext, useEffect } from "react";
import { firebaseAuth } from "../provider/AuthProvider";
import { useNavigate } from "react-router-dom";
import { StyledButton } from "../components/Customs";
import { snackNotification } from "../provider/SnackProvider";
import Spacer from "../components/Spacer";
import Wrapper from "../components/Wrapper";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import useContentful from "../hooks/useContentful";
import LoadingWrapper from "../components/LoadingWrapper";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputLabel,
  Typography,
  styled,
} from "@mui/material";

const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

const Signup = () => {
  const { handleSignupEmail, inputs, setInputs, errors, user } = useContext(firebaseAuth);
  const fields = useContentful("signup");
  const { triggerSnackbar } = useContext(snackNotification);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      const id = localStorage.getItem("temp-values-id");
      if (id) {
        localStorage.removeItem("temp-values-id");
        triggerSnackbar({ message: fields.snackbar });
        navigate(`/results/${id}`);
      } else {
        triggerSnackbar({ message: fields.snackbar });
        navigate(`/`);
      }
    }
  }, [user]);

  async function handleSubmit(e) {
    e.preventDefault();
    console.log("handleSubmit");
    //wait to signup
    await handleSignupEmail();
    //push home
    // navigate("/")
  }

  function handleChange(e) {
    const { name, value } = e.target;
    console.log(e);

    console.log(inputs);
    setInputs((prev) => ({ ...prev, [name]: value }));
  }

  if (!fields) {
    return <LoadingWrapper />;
  }

  return (
    <Wrapper>
      <form onSubmit={handleSubmit}>
        <Typography variant="h3">{fields.title}</Typography>
        <Typography component="div">{documentToReactComponents(fields.description)}</Typography>
        <StyledFormControl>
          <InputLabel>{fields.emailTitle}</InputLabel>
          <Input onChange={handleChange} name="email" placeholder="email" value={inputs.email} />
          <FormHelperText>{fields.emailCaption}</FormHelperText>
        </StyledFormControl>
        <Spacer />
        <StyledFormControl>
          <InputLabel>{fields.passwordTitle}</InputLabel>
          <Input
            onChange={handleChange}
            type="password"
            name="password"
            placeholder="password"
            value={inputs.password}
          />
          <FormHelperText>{fields.passwordCaption}</FormHelperText>
        </StyledFormControl>
        <Spacer i={2} />
        <StyledFormControl>
          <FormControlLabel
            control={
              <Checkbox
                onChange={() =>
                  handleChange({
                    target: {
                      name: "terms",
                      value: !inputs.terms,
                    },
                  })
                }
                checked={inputs.terms}
                name="terms"
              />
            }
            label={fields.terms}
          />
        </StyledFormControl>
        <Spacer />
        {errors.length > 0
          ? errors.map((error) => <Typography color="error">{error}</Typography>)
          : null}
        <StyledButton variant="contained" color="primary" type="submit" disabled={!inputs.terms}>
          Registrierung
        </StyledButton>
      </form>
    </Wrapper>
  );
};

export default Signup;
