import React, { useContext, useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { functions } from "../firebase/firebaseApp";
import { firebaseAuth } from "../provider/AuthProvider";
import Wrapper from "../components/Wrapper";
import { Box, Divider, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { StyledButtonInverted } from "../components/Customs";

const CheckoutForm = ({ amount, stripePromise, cart }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [message, setMessage] = useState("");

  const [clientSecret, setClientSecret] = useState(null);

  const { isLoading, user } = useContext(firebaseAuth);

  // const stripe = useStripe();
  // const elements = useElements();

  useEffect(() => {
    // if (amount) {
    //   const createPaymentIntent = httpsCallable(functions, "api/createPaymentIntent");
    //   createPaymentIntent({ amount: amount }).then((result) => {
    //     const clS = result.data.clientSecret;
    //     setClientSecret(clS);
    //   });
    // }

    if (cart) {
      console.log("cart: ", cart);
      const data = {
        items: cart,
        returnUrl: window.location.origin + "/completion",
      };

      if (user && user.appUser) {
        data.customerEmail = user.appUser.email;
        data.customerFullName = user.appUser.name + " " + user.appUser.surname;
      }

      const createCheckoutSession = httpsCallable(functions, "api/create-checkout-session");
      createCheckoutSession(data).then((result) => {
        const clS = result.data.clientSecret;
        setClientSecret(clS);
      });
    }
  }, [amount, cart, user]);

  const handleSubmit = async (event) => {
    // event.preventDefault();
    // setIsProcessing(true);
    // setIsProcessing(true);
    // const { error } = await stripe.confirmPayment({
    //   elements,
    //   confirmParams: {
    //     // Make sure to change this to your payment completion page
    //     return_url: `${window.location.origin}/completion`,
    //   },
    // });
    // if (error.type === "card_error" || error.type === "validation_error") {
    //   setMessage(error.message);
    // } else {
    //   setMessage("An unexpected error occured.");
    // }
    // setIsProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      {stripePromise && clientSecret && (
        <Box padding={"0 2rem"}>
          <EmbeddedCheckoutProvider
            key={clientSecret}
            stripe={stripePromise}
            options={{ clientSecret }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>

          {paymentStatus && <p>{paymentStatus}</p>}
          {message && <p>{message}</p>}
        </Box>
      )}
    </form>
  );
};

const Orders = () => {
  const [selectedTests, setSelectedTests] = useState(new Map());
  const [totalAmount, setTotalAmount] = useState(0);
  const [currency, setCurrency] = useState("EUR");
  const [products, setProducts] = useState([]);
  const [stripePromise, setStripePromise] = useState(null);
  const [activePage, setActivePage] = useState("order");

  const { user } = useContext(firebaseAuth);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const fncProducts = httpsCallable(functions, "api/getProducts");
        const response = await fncProducts();
        if (response.data) {
          const data = response.data;
          setProducts(data);
          if (data.length > 0) {
            const prod = data[0];
            const defaultPrice = prod.prices.find((price) => price.id === prod.defaultPrice);
            if (defaultPrice) {
              setCurrency(defaultPrice.curency.toUpperCase());
            }
          }
        } else {
          console.log("No products found");
          setProducts([]);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    const fetchPromise = async () => {
      const configStripe = httpsCallable(functions, "api/configStripe");
      configStripe().then((result) => {
        const { publishableKey } = result.data;
        setStripePromise(loadStripe(publishableKey));
      });
    };

    if (user) {
      fetchPromise();
      fetchProducts();
    }
  }, [user]);

  useEffect(() => {
    if (!user) return;
  }, [user]);

  const getPrice = (price_id) => {
    if (!price_id) return { unit_amount: 0 };
    const product = products.find((product) =>
      product.prices.some((price) => price.id === price_id)
    );
    const price = product.prices.find((price) => price.id === price_id);
    return price;
  };

  const handleTestSelection = (e) => {
    console.log(e);
    const { name, value } = e.target;

    const updatedTests = new Map(selectedTests);
    if (value === 0) {
      updatedTests.delete(name);
    } else {
      updatedTests.set(name, { price: name, quantity: parseInt(value) });
    }

    setSelectedTests(updatedTests);
    // setSelectedTests();
    const total = updatedTests
      .entries()
      .reduce(
        (acc, [price_id, { _, quantity }]) =>
          acc + (quantity * getPrice(price_id).unit_amount) / 100,
        0
      );
    setTotalAmount(total);
  };

  const clickNext = () => {
    setActivePage("checkout");
  };

  return (
    <Wrapper>
      <Typography variant="h3" align="center">
        Order Tests
      </Typography>

      {activePage === "order" && (
        <Grid container spacing={2} marginTop={3}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              {products.map((product) => (
                <>
                  <Grid item key={product.id + "_info"} xs={12}>
                    <Typography variant="h5">{product.name}</Typography>
                    <Typography variant="body1" marginBottom={3}>
                      {product.description}
                    </Typography>
                  </Grid>
                  {product.prices.map((price, pindex) => (
                    <>
                      <Grid item xs={4}>
                        <TextField
                          label="Qty:"
                          name={price.id}
                          key={price.id + "_quantity"}
                          select
                          fullWidth
                          size="small"
                          id={price.id}
                          onChange={handleTestSelection}
                          value={selectedTests.get(price.id)?.quantity || 0}
                        >
                          {[...Array(6)].map((_, index) => {
                            const id = `amount_${price.id}_${index}`;
                            return (
                              <MenuItem id={id} key={id} value={index}>
                                {index}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                      <Grid item xs={8}>
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          alignItems="center"
                          key={pindex + "_box"}
                          sx={{ height: "100%" }}
                        >
                          <Typography variant="body1" align="right">
                            Price: {price.unit_amount / 100} {price.currency.toUpperCase()}
                          </Typography>
                        </Box>
                      </Grid>
                    </>
                  ))}
                </>
              ))}
              <Grid item xs={12} justifyContent="flex-end">
                <Divider />
                <Typography variant="body1" align="right">
                  Total: {totalAmount + " " + currency}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box marginLeft={"auto"} width={"80%"}>
              {/* add name and address information here */}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <StyledButtonInverted onClick={clickNext}> Weiter </StyledButtonInverted>
          </Grid>
        </Grid>
      )}
      {activePage === "checkout" && (
        <div className="payment-section">
          <CheckoutForm
            amount={null}
            stripePromise={stripePromise}
            cart={[...selectedTests.values()]}
          />
        </div>
      )}
    </Wrapper>
  );
};

export default Orders;
