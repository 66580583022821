import React, { useContext } from "react";
import { useNavigate } from "react-router-dom"; // Change this import
import { firebaseAuth } from "../provider/AuthProvider";
import { StyledButton } from "../components/Customs";
import Wrapper from "../components/Wrapper";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import useContentful from "../hooks/useContentful";
import LoadingWrapper from "../components/LoadingWrapper";
import Spacer from "../components/Spacer";
import { snackNotification } from "../provider/SnackProvider";
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Link,
  Typography,
  styled,
} from "@mui/material";

const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

function PasswordReset() {
  const { handlePasswordResetEmail, inputs, setInputs } = useContext(firebaseAuth);
  const fields = useContentful("passwordReset");
  const { triggerSnackbar } = useContext(snackNotification);
  const navigate = useNavigate();

  function handleReset(e) {
    e.preventDefault();
    console.log("handlePasswordResetEmail");
    handlePasswordResetEmail();
    triggerSnackbar({ message: fields.snackbar });
    navigate("/signin");
  }

  function handleChange(e) {
    const { name, value } = e.target;
    console.log(inputs);
    setInputs((prev) => ({ ...prev, [name]: value }));
  }

  if (!fields) {
    return <LoadingWrapper />;
  }

  return (
    <Wrapper>
      <form onSubmit={handleReset}>
        <Typography variant="h3">{fields.title}</Typography>
        <Typography component="div">{documentToReactComponents(fields.description)}</Typography>
        <StyledFormControl>
          <InputLabel>{fields.emailTitle}</InputLabel>
          <Input onChange={handleChange} name="email" placeholder="email" value={inputs.email} />
          <FormHelperText>{fields.emailCaption}</FormHelperText>
        </StyledFormControl>
        <StyledButton variant="contained" color="primary" type="submit">
          {fields.button}
        </StyledButton>
        <Spacer i={2} />
        <Typography>
          {fields.signinCta}{" "}
          <Link href="/signin" color="primary">
            {fields.signinButton}
          </Link>
        </Typography>
        <Spacer />
        <Typography>
          {fields.signupCta}{" "}
          <Link href="/signup" color="primary">
            {fields.signupButton}
          </Link>
          .
        </Typography>
      </form>
    </Wrapper>
  );
}

export default PasswordReset;
